

// -------------------------------------------------------------
// Navigation Scroll
// -------------------------------------------------------------

    var iframe = $('#vimeo_player')[0],
        player = $f(iframe);

    player.addEvent('ready', function() {
        player.api('setVolume', 0);
    });

$(window).scroll(function(event) {
    Scroll();
}); 

$('.navbar-nav li a').click(function() {  
    $('html, body').animate({scrollTop: $(this.hash).offset().top -1}, 1000);
    return false;
});

// User define function
function Scroll() {
    var contentTop      =   [];
    var contentBottom   =   [];
    var winTop      =   $(window).scrollTop();
    var rangeTop    =   200;
    var rangeBottom =   500;
    $('.navbar-nav').find('.scroll a').each(function(){
        contentTop.push( $( $(this).attr('href') ).offset().top);
        contentBottom.push( $( $(this).attr('href') ).offset().top + $( $(this).attr('href') ).height() );
    })
    $.each( contentTop, function(i){
        if ( winTop > contentTop[i] - rangeTop ){
            $('.navbar-nav li.scroll')
            .removeClass('current')
            .eq(i).addClass('current');          
        }
    })

};

$('.navbar-nav>li>a').on('click', function(){
    $('.navbar-collapse').collapse('hide');
});
    
// -------------------------------------------------------------
//  Sticky Nav
// -------------------------------------------------------------
(function () {  
    function menuToggle(){
        var windowWidth = $(window).width();
        if(windowWidth > 991 ){
            $(window).on('scroll', function(){
                if( $(window).scrollTop()>735 ){
                    $('.home-two .navbar').addClass('fixed-top animated fadeInDown');
                } else {
                    $('.home-two .navbar').removeClass('fixed-top animated fadeInDown');
                };
            });
        }else{
            
            $('.home-two .navbar').addClass('fixed-top');
                
        };  
    }

    menuToggle();
}()); 




$(document).ready(function(){
	
	    $('.customer-logos').slick({
        slidesToShow: 6,
        slidesToScroll: 2,
        autoplay: true,
        infinite: true,
        autoplaySpeed: 3000,
        arrows: true,
        dots: false,
        pauseOnHover: false,
       

        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 4
            }
        }, {
            breakpoint: 520,
            settings: {
                slidesToShow: 3
            }
        }]
    });
	  
});
$(window).on("load", function (e) {
	
	
    cookieAkkoord = Cookies.get('cookieAkkoord');
    if(cookieAkkoord == undefined){
        $('.cookie').fadeIn(300);
    }
    $('.close_cookie').on('click',function(e){
        Cookies.set('cookieAkkoord', 'shown');
        $('.cookie').fadeOut(300);
    });
        if($('#contactUsMap2').length){
        initContactUsMap2();
    }
})

$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});



